import { ReactNode, useState, useRef } from "react";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  IconButton,
  forwardRef,
  Link,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { IoSearchOutline, IoCalendarOutline } from "react-icons/io5";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

interface FilterProps {
  placeholder: string;
  loading?: boolean;
  filterTable?: (searchParam: string) => void;
  children?: ReactNode;
  searchFunction?: (filter: string) => void;
  setStartDate?: (start: Date) => void;
  setEndDate?: (end: Date) => void;
  startDate?: Date;
  endDate?: Date;
  isVisibleDatepicker?: boolean;
  isVisibleSearchButton?: boolean;
}

function Filter({
  filterTable,
  searchFunction,
  placeholder,
  loading,
  children,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  isVisibleDatepicker = false,
  isVisibleSearchButton = true,
}: FilterProps) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const inputRef = useRef(null);
  registerLocale("ptBR", ptBR);

  const onMonthChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const CustomInput = forwardRef(({ value }: any, ref) => (
    <Button
      onClick={() => setIsDatePickerOpen(true)}
      ref={ref}
      p="5px 12px 5px 20px"
      maxW="220px"
      w="100%"
      fontSize="14px"
      borderRadius="4"
      _hover={{}}
      bgColor="gray.49"
      fontWeight="500"
      leftIcon={
        !startDate &&
        !endDate && <IoCalendarOutline color="gray.300" size={20} />
      }
    >
      {value}
    </Button>
  ));

  const handleSearch = () => {
    let searchText = inputRef.current.value;
    if (filterTable) {
      filterTable(searchText);
      return;
    }
    if (searchFunction) {
      searchFunction(searchText);
      return;
    }
  };

  const clearFilters = (e: any) => {
    e.preventDefault();
    inputRef.current.value = "";
    if (setStartDate) {
      setStartDate(null);
    }
    if (setEndDate) {
      setEndDate(null);
    }
    handleSearch();
  };

  return (
    <Flex
      bg="gray.25"
      p={2}
      borderRadius={8}
      mb={4}
      w="100%"
      gap="10"
      align="center"
    >
      <InputGroup maxW={500} bg="white">
        <InputLeftElement
          pointerEvents="none"
          children={<IoSearchOutline color="gray.300" size={20} />}
          height={35}
        />
        <Input
          placeholder={placeholder}
          type="search"
          fontSize="1rem"
          height={35}
          ref={inputRef}
        />
        <InputRightElement maxW="70px" w="100%" mr="2">
          {loading &&
            <Spinner size="sm" />
          }
        </InputRightElement>
        {/* <InputRightElement children={<Button>Buscar</Button>} /> */}
      </InputGroup>
      {children}

      <Flex align="center" gap="2" bgColor="gray.49" borderRadius={8} px="2">
        {isVisibleDatepicker && (
          <DatePicker
            locale="ptBR"
            selected={startDate}
            onChange={(date) => onMonthChange(date)}
            startDate={startDate}
            endDate={endDate}
            onInputClick={() => setIsDatePickerOpen(true)}
            onClickOutside={() => setIsDatePickerOpen(false)}
            open={isDatePickerOpen}
            customInput={<CustomInput />}
            selectsRange
            dateFormat="dd/MM/yyyy"
          />
        )}
        {isVisibleSearchButton && (
          <IconButton
            aria-label="Search"
            bgColor="transparent"
            onClick={() => handleSearch()}
            icon={<IoSearchOutline color="gray.300" size={20} />}
          />
        )}
      </Flex>

      <a href="#" onClick={clearFilters}>
        <Link color="blue.50">Limpar</Link>
      </a>
    </Flex>
  );
}

export { Filter };
