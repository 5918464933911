import { useState } from "react";
import {
  Flex,
  Switch,
  Spacer,
  Input,
  InputGroup,
  IconButton,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSettings, useIntegration } from "@/hooks";
import { showToast, copyTextToClipboard } from "@/utils";
import { FormModal } from "@/components";
import { EditSellerTokenActive, SellerTokens, SellerWebhooks } from "@/models";
import { TrashIcon } from "@/styles/icons/TrashIcon";
import { EditIcon } from "@/styles/icons/EditIcon";
import { DuplicateIcon } from "@/styles/icons/CopyIcon";

interface TokenItemProps {
  token: SellerTokens;
}
const tokenCreateScheama = yup.object().shape({
  title: yup.string().required("Preenchimento obrigatório!"),
});

export const TokenItem = ({ token }: TokenItemProps) => {
  const [showToken, setShowToken] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { putSellerApiToken } = useSettings();
  const { handleModal, isLoading, setIsLoading } = useIntegration();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(tokenCreateScheama),
  });
  const getTitleValue = watch("title");
  const onSubmit = handleSubmit(async (data: EditSellerTokenActive) => {
    try {
      setIsLoading(true);
      await putSellerApiToken(token.uuid, {
        title: data.title,
        active: token.active,
      });
      setValue("textTilte", data.title);
      showToast({ message: "Token atualizado com sucesso!", type: "success" });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
      setValue("textTilte", token.title);
    } finally {
      setIsEditModalOpen(!isEditModalOpen);
      setIsLoading(false);
    }
  });

  return (
    <>
      <FormModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
        title="Editar chave API"
        handleConfirmModal={onSubmit}
        isSubmitting={isLoading}
        confirmationButtonText="Salvar"
        disableWhenIsFormEmpty={getTitleValue === ""}
      >
        <Input
          name="title"
          defaultValue={token.title}
          {...register("title")}
          borderRadius="2px"
        />
      </FormModal>

      <Flex
        w="100%"
        bgColor="gray.25"
        p="8px"
        align="center"
        gap={6}
        borderRadius="4px"
      >
        <InputGroup maxW="280px">
          <Input
            name="textTitle"
            {...register("textTilte")}
            defaultValue={token.title}
            border="none"
            isDisabled
            color="black"
            _disabled={{
              color: "black",
              bgColor: "transparent",
            }}
          />
        </InputGroup>
        <Spacer />
        <Flex
          maxW="360px"
          w="100%"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="4px"
        >
          <Input
            name="key"
            defaultValue={token.uuid}
            readOnly
            bg="transparent"
            border="none"
            type={showToken ? "text" : "password"}
            p="5px"
            fontSize={showToken ? "12px" : ""}
            w="100%"
            _focus={{
              border: "none",
            }}
            _hover={{
              cursor: "text",
            }}
          />
          <IconButton
            aria-label="copy to clipboard"
            bgColor="transparent"
            icon={<DuplicateIcon />}
            opacity={showToken ? "1" : "0"}
            onClick={() =>
              showToken
                ? copyTextToClipboard(
                    token.uuid,
                    "Token copiado para a área de transferência!"
                  )
                : ""
            }
            _hover={{
              cursor: showToken ? "pointer" : "default",
            }}
          />

          <IconButton
            aria-label="show/hide token"
            bgColor="transparent"
            icon={
              showToken ? (
                <AiOutlineEyeInvisible size="20px" />
              ) : (
                <AiOutlineEye size="20px" />
              )
            }
            onClick={() => setShowToken(!showToken)}
            _hover={{
              bgColor: "transparent",
            }}
          />
        </Flex>
        <IconButton
          aria-label="edit title"
          bgColor="transparent"
          icon={<EditIcon />}
          onClick={() => {
            setIsEditModalOpen(!isEditModalOpen);
          }}
          _hover={{
            bgColor: "transparent",
          }}
        />
        <IconButton
          aria-label="delete token"
          bgColor="transparent"
          icon={<TrashIcon />}
          onClick={() => handleModal({ type: "delete", token })}
          _hover={{
            bgColor: "transparent",
          }}
        />
        <Switch
          isChecked={token.active}
          onChange={() => handleModal({ type: "changeActive", token })}
        />
      </Flex>
    </>
  );
};
