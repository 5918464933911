
import {
    Button, Divider, Flex, Text, ListItem, UnorderedList, Image, Box, Img
} from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatCurrency, getApplicationName } from "@/utils";
import React from "react";


interface PreviewLink {
    arrayInstallments?: any;
    creditCardTax?: string;
    valuePerMonth?: number;
    aditionalValue?: string;
    nameLink?: string;
    title?: string;
    cliente?: string;
    description?: string;
    value?: number;
    enablePix?: boolean;
    enableBankSlip?: boolean;
    enableCreditCard?: boolean;
    boleto?: {
        installments: number;
        discount?: string;
        tax?: string;
    }
    creditcard?: {
        installments?: number;
        discount?: any;
        scheduleDate?: string;
        tax?: string;
    }
    pix?: {
        installments: number;
        discount?: string;
        tax?: string;
    }
    expireDate?: string;
    hasDescription?: boolean;
    custonFields?: any;
}

interface PreviewDataProps {
    previewData: PreviewLink;
    isPontual?: boolean;
}


const PreviewLinkPagamento = ({ previewData, isPontual, }: PreviewDataProps) => {
    const [previewButton, setPreviewButton] = useState<"resumo" | "parcelamento">("parcelamento")
    const [hasToogle, setHasToogle] = useState<boolean>(false);
    const hasView = localStorage.getItem("barte-features-list-user").includes("view_new_preview_payment_link")
    const haswMixInstallments = localStorage.getItem("hasMixInstallments")


    const valueDescontCredicard = (discount) => {


        const formatNumber = (valorString) => {
            const numberValue = Number(valorString.replace(/[^\d,-]/g, '').replace(',', '.'));
            return isNaN(numberValue) ? 0 : numberValue;
        };
        const formatCurrency = (numberValue) => {
            return numberValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        };

        if (previewData.aditionalValue) {
            if (discount) {

                const value = formatNumber(previewData?.value);
                const additionalValue = formatNumber(previewData?.aditionalValue);
                const originalDiscount = formatNumber(discount);
                const desconto = (originalDiscount / 100) * value;
                const ValueAfterDescont = value - desconto;
                const finalValueAfterDiscount = formatCurrency(ValueAfterDescont + additionalValue);
                return finalValueAfterDiscount;
            } else {
                const valueTotal = formatNumber(previewData?.value) + formatNumber(previewData?.aditionalValue)
                return formatCurrency(Number(valueTotal))
            }
        } else {
            if (discount) {

                const value = formatNumber(previewData?.value);
                const originalDiscount = formatNumber(discount);
                const desconto = (originalDiscount / 100) * value;
                const finalValue = value - desconto;
                const finalValueAfterDiscount = formatCurrency(finalValue);
                return finalValueAfterDiscount;
            } else {

                return `R$ ${previewData.value}`
            }
        }
    }

    useEffect(() => {
        if (hasView) {
            setHasToogle(hasView)
        }

    }, [hasView])
    useEffect(() => {
        isPontual ? setPreviewButton("parcelamento") : setPreviewButton("resumo")
    }, [isPontual])


    return (

        <Flex w="33%" pl="25px">
            <Flex flexDirection="column" gap="28px"  >
                <Flex
                    gap="12px"
                    w="237px"
                    border="1px solid rgba(195, 202, 215, 1)"
                    borderRadius="4px"
                    p="1.5px"
                >
                    <Button
                        w="100%"
                        h="28px"
                        fontSize="14px"
                        fontWeight="500"
                        borderRadius="2px"
                        color={previewButton === "resumo" ? "rgba(24, 144, 255, 1)" : "rgba(0, 0, 0, 1)"}
                        border={previewButton === "resumo" ? "1px solid rgba(24, 144, 255, 1)" : ""}
                        backgroundColor="white"
                        onClick={() => setPreviewButton("resumo")}
                    >
                        Resumo

                    </Button>

                    {isPontual &&
                        <Button
                            w="100%"
                            h="28px"
                            fontSize="14px"
                            fontWeight="500"
                            borderRadius="2px"
                            color={previewButton === "parcelamento" ? "rgba(24, 144, 255, 1)" : "rgba(0, 0, 0, 1)"}
                            border={previewButton === "parcelamento" ? "1px solid rgba(24, 144, 255, 1)" : ""}
                            backgroundColor="white"
                            onClick={() => setPreviewButton("parcelamento")}

                        >Parcelamento</Button>
                    }
                </Flex>
                {previewButton === 'resumo' && <>
                    <Flex className="description" flexDirection="column" gap="12px">
                        <Text mb="7px" fontSize="1.5rem" fontWeight="600" lineHeight="33.58px" color="rgba(0, 0, 0, 1)"
                        >{previewData?.title}
                        </Text>

                        <Flex>
                            {previewData?.value &&
                                <>
                                    {isPontual &&
                                        <Text
                                            fontSize="1rem" fontWeight="600" lineHeight="22.38px" color="rgba(0, 0, 0, 1)"
                                        > R$ {previewData?.value}
                                        </Text>

                                    }

                                    {!isPontual &&
                                        <>

                                            <Text
                                                fontSize="1rem" fontWeight="600" lineHeight="22.38px" color="rgba(0, 0, 0, 1)"
                                            >{previewData?.valuePerMonth?.toLocaleString('pt-BR', { style: 'currency', currency: "BRL" })}

                                            </Text>
                                            <Text>
                                                / mensal
                                            </Text>

                                        </>
                                    }
                                </>


                            }

                        </Flex>
                        <Text
                            fontSize="0.75rem"
                            fontWeight="400"
                            lineHeight="22.38px"
                            color={previewData?.cliente ? 'black' : "rgba(138, 148, 168, 1)"}
                        >

                            {previewData?.cliente || "Sem cliente associado"}

                        </Text>

                        <Text
                            fontSize="0.75rem"
                            fontWeight="400"
                            lineHeight="22.38px"
                            color="rgba(0, 0, 0, 1)"
                        >
                            {previewData?.description}
                        </Text>
                    </Flex>
                    <Divider />

                    <Flex className="Boleto" flexDirection="column" gap="15px">
                        <Flex gap="8px">

                            <Image fontSize="1.2rem" w="24px" h="24px" src={!previewData?.enableBankSlip ? "/images/icons/DesabledBoleto.svg" : "/images/icons/ActiveBoleto.svg"} />
                            <Text color="rgba(0, 0, 0, 1)">

                                Boleto
                            </Text>
                        </Flex>
                        {previewData?.value && previewData?.enableBankSlip &&

                            <Flex alignItems="center" gap="7px" pl="32px" color="rgba(0, 0, 0, 1)" >
                                {previewData.value &&
                                    <>
                                        <Text fontSize="1rem" fontWeight="600"      >
                                            {valueDescontCredicard(previewData?.boleto?.discount)}
                                        </Text>
                                        {previewData?.boleto?.discount &&
                                            <Text fontSize="0.75rem" color="rgba(10, 161, 124, 1)"               >
                                                {`${previewData?.boleto?.discount.replace("%", "")}%`} de desconto
                                            </Text>
                                        }
                                        {isPontual &&
                                            <Text fontSize="0.75rem" >
                                                em até {previewData?.boleto?.installments}x
                                            </Text>
                                        }
                                    </>
                                }

                            </Flex>
                        }

                    </Flex>
                    <Flex className="Credit" flexDirection="column" gap="15px">
                        <Flex gap="8px" >
                            {previewData?.enableCreditCard ?
                                <Image w="24px" h="24px" src={"/images/icons/ActiveCreditCard.svg"} />
                                : <Image w="24px" h="24px" src={"/images/icons/DesabledCreditCard.svg"} />
                            }

                            <Text color="rgba(0, 0, 0, 1)">

                                Cartão de crédito
                            </Text>
                        </Flex>
                        {previewData?.enableCreditCard &&
                            <Flex gap="7px" flexDirection="column" >
                                {previewData?.value &&
                                    <>
                                        <Flex alignItems="center" gap="7px" pl="32px" >

                                            <Text fontSize="1rem" fontWeight="600" color="rgba(0, 0, 0, 1)"     >
                                                {valueDescontCredicard(previewData?.creditcard?.discount)}
                                            </Text>

                                            {previewData?.creditcard?.discount &&
                                                <Text fontSize="0.75rem" color="rgba(10, 161, 124, 1)"               >
                                                    {`${previewData?.creditcard?.discount.replace("%", "")}%`} de desconto
                                                </Text>
                                            }


                                        </Flex>

                                        <Flex flexDirection="column" pl="32px" color="rgba(0, 0, 0, 1)" gap="12px"  >
                                            {isPontual && haswMixInstallments === "false" ?

                                                <Text fontSize="0.75rem">
                                                    em até {previewData?.creditcard?.installments}x
                                                </Text> :
                                                <>
                                                    <Text fontSize="0.75rem">
                                                        em até 21x
                                                    </Text>
                                                    <Text fontSize="0.75rem">
                                                        sem juros em até {previewData?.creditcard?.installments}x
                                                    </Text>
                                                </>

                                            }
                                            {previewData?.creditcard?.scheduleDate &&

                                                <Text fontSize="0.75rem">
                                                    Cobrança agendada para {previewData?.creditcard?.scheduleDate}
                                                </Text>
                                            }

                                            {isPontual && haswMixInstallments === 'false' &&
                                                <>
                                                    {previewData?.creditCardTax === "EARLY_SELLER" ?
                                                        <Text fontSize="0.75rem">
                                                            Taxas para <b>Você</b>
                                                        </Text> :
                                                        <Text fontSize="0.75rem">
                                                            Taxas para o <b>Cliente</b>
                                                        </Text>}
                                                </>
                                            }
                                        </Flex>
                                    </>}

                            </Flex>
                        }

                    </Flex>
                    <Flex className="Pix" flexDirection="column" gap="7px">
                        <Flex gap="8px">
                            <Image fontSize="1.2rem" w="24px" h="24px" src={!previewData?.enablePix ? "/images/icons/DesabledPix.svg" : "/images/icons/ActivePix.svg"} />
                            <Text color="rgba(0, 0, 0, 1)">
                                PIX
                            </Text>
                        </Flex>
                        {previewData?.value && previewData?.enablePix &&

                            <Flex alignItems="center" gap="7px" pl="32px" color="rgba(0, 0, 0, 1)" >
                                <Text fontSize="1rem" fontWeight="600"      >
                                    {valueDescontCredicard(previewData?.pix?.discount)}

                                </Text>

                                {previewData?.pix?.discount &&
                                    <Text fontSize="0.75rem" color="rgba(10, 161, 124, 1)"               >
                                        {`${previewData?.pix?.discount.replace("%", "")}%`} de desconto
                                    </Text>
                                }
                                {isPontual &&
                                    <Text fontSize="0.75rem" >
                                        em até {previewData?.pix?.installments}x
                                    </Text>
                                }

                            </Flex>
                        }

                    </Flex>

                    <Divider />

                    <Text
                        fontSize="1rem" fontWeight="600" lineHeight="22.38px" color="rgba(0, 0, 0, 1)"
                    >
                        Configurações avançada
                    </Text>

                    <UnorderedList fontSize="0.75rem" color="rgba(0, 0, 0, 1)" spacing={3} p=" 0 20px">

                        {previewData?.expireDate &&
                            <ListItem fontSize="0.75rem" >Link expira {previewData?.expireDate}</ListItem>
                        }

                        {previewData?.hasDescription &&
                            <ListItem fontSize="0.75rem">Descrição no boleto: habilitada </ListItem>
                        }
                        {previewData?.custonFields?.metadados && previewData?.custonFields?.metadados?.length > 0 &&

                            <ListItem fontSize="0.75rem">
                                Campos customizados:
                                {Array.isArray(previewData?.custonFields?.metadados)
                                    ? previewData?.custonFields?.metadados.map((item: { key: any; value: any }) => {
                                        return `${item?.key} : ${item?.value}; `;
                                    })
                                    : ""}
                            </ListItem>
                        }
                    </UnorderedList>

                </>}


                {previewButton === "parcelamento" &&

                    <Flex flexDirection="column" maxW="400px" gap="23px">
                        {console.log("previewData", previewData)}
                        {console.log("previewData", previewData?.arrayInstallments)}
                        {console.log("mixInstalments", haswMixInstallments)}
                        {previewData?.enableCreditCard &&

                            < Flex id="card" flexDirection="column" gap="23px">
                                <Flex flexDirection="column">
                                    <Flex fontWeight="600" lineHeight="22.38px" color="rgba(0, 0, 0, 1)" justifyContent="space-between">
                                        <Text >
                                            Cartão de crédito
                                        </Text>
                                        <Text >

                                            {valueDescontCredicard(previewData?.creditcard?.discount)}
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent="space-between">
                                        {isPontual &&
                                            <>
                                                {previewData?.creditCardTax === "EARLY_SELLER" ??
                                                    <Text fontSize="0.75rem">
                                                        Taxas para <b>Você</b>
                                                    </Text>}
                                                {previewData?.creditCardTax === "EARLY_BUYER" ??

                                                    <Text fontSize="0.75rem">
                                                        Taxas para o <b>Cliente</b>
                                                    </Text>}
                                            </>
                                        }

                                        {previewData?.creditcard?.discount &&

                                            <Text fontSize="12px" color="rgba(10, 161, 124, 1)">

                                                {previewData?.creditcard?.discount} de desconto
                                            </Text>
                                        }

                                    </Flex>

                                </Flex>

                                {haswMixInstallments === "true" && (
                                    <Flex
                                        flexDir="column"
                                        border="1px solid"
                                        borderColor="newGray.100"
                                        borderRadius="4px"
                                        padding=".8rem"
                                        gap="35px"
                                    >
                                        <Text
                                            fontSize=".9rem"
                                            color="black"
                                            fontWeight="400"
                                        >
                                            Valor base apresentado para o cliente: {" "}
                                            <b>
                                                R$ {previewData?.value}
                                            </b>
                                            <Text
                                                fontSize=".9rem"
                                                color="newGray.400"
                                                fontWeight="400"
                                            >
                                                Você assume as taxas em até <b>
                                                    {previewData?.creditcard?.installments}x
                                                </b>
                                            </Text>
                                        </Text>


                                        <Flex
                                            flexDir="column"
                                            gap=".5rem"
                                        >
                                            <Flex
                                                w="100%"
                                                justifyContent="space-between"

                                            >
                                                <Text
                                                    w="120%"
                                                >
                                                    Cliente paga
                                                </Text>
                                                <Text
                                                    w="80%"

                                                >
                                                    Você recebe
                                                </Text>
                                            </Flex>




                                            <Flex gap=".5rem">

                                                <Flex
                                                    w='80%'
                                                    height="89px"
                                                    border="1px solid #EDF1F7"
                                                    borderRadius="2px"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    paddingLeft="16px"
                                                    fontSize="14px"
                                                    gap='3px'
                                                >
                                                    <Text
                                                        fontWeight="600"
                                                        color="black"
                                                    >
                                                        até
                                                    </Text>
                                                    <Text>
                                                        21 x
                                                    </Text>
                                                </Flex>
                                                <Flex
                                                    w='30%'
                                                    height="89px"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    paddingLeft="16px"
                                                >
                                                    <Img w="37px" h="52px" src="/images/commons/ArrowToRight.svg" />

                                                </Flex>
                                                <Flex
                                                    w="85%"
                                                    height="89px"
                                                    border="1px solid #EDF1F7"
                                                    borderRadius="2px"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    paddingLeft="16px"
                                                    gap="3px"
                                                ><Text>
                                                        1 x
                                                    </Text>

                                                    <Text
                                                        fontWeight="600"
                                                        color="black"
                                                    >
                                                        {formatCurrency({ value: previewData?.arrayInstallments?.installmentsToSeller?.at(-1).receivedAmount })}


                                                    </Text>
                                                </Flex>
                                            </Flex>

                                        </Flex>
                                    </Flex>
                                )}

                                {(previewData?.arrayInstallments && previewData?.value) && haswMixInstallments === "false" && (
                                    <Flex
                                        flexDir="column"
                                        border="1px solid"
                                        borderColor="newGray.100"
                                        borderRadius="4px"
                                        padding=".8rem"
                                        gap="35px"
                                    >

                                        <Text
                                            fontSize=".9rem"
                                            color="black"
                                            fontWeight="400"
                                        >
                                            Valor base apresentado para o cliente: <b>
                                                {formatCurrency({ value: previewData?.arrayInstallments?.installmentsToBuyer?.at(0)?.totalValue })}
                                            </b>
                                            {previewData?.creditCardTax === "EARLY_SELLER" && hasToogle === true && (
                                                <Text
                                                    fontSize=".9rem"
                                                    color="newGray.400"
                                                    fontWeight="400"
                                                >
                                                    Parcelamento máximo selecionado: <b>
                                                        {previewData?.arrayInstallments?.installmentsToSeller.length}x
                                                    </b>
                                                </Text>)
                                            }
                                        </Text>


                                        <Flex
                                            flexDir="column"
                                            gap=".5rem"
                                        >
                                            <Flex
                                                w="100%"
                                                justifyContent="space-between"

                                            >
                                                <Text
                                                    w="120%"
                                                >
                                                    Cliente paga
                                                </Text>
                                                <Text
                                                    w="80%"

                                                >
                                                    Você recebe
                                                </Text>
                                            </Flex>

                                            {previewData?.creditCardTax === "EARLY_BUYER" && (
                                                <Flex flexDir="column"
                                                    gap=".5rem">

                                                    {previewData?.arrayInstallments?.installmentsToBuyer?.map((item, index) => (
                                                        <Flex
                                                            justifyContent="space-between"
                                                            key={index}
                                                        >
                                                            <Flex gap=".5rem"
                                                                w="130%"

                                                            >
                                                                <Flex flexDirection="column">
                                                                    <Flex
                                                                        gap="10px"
                                                                    >
                                                                        <Text
                                                                            fontSize=".9rem"
                                                                            color="newGray.400"
                                                                            fontWeight="400"
                                                                            aria-label={`Amount paind in ${index + 1} times`}
                                                                        >
                                                                            {item?.installments}x
                                                                        </Text>
                                                                        <Text
                                                                            fontSize=".9rem"
                                                                            color="black"
                                                                            fontWeight="400"
                                                                            aria-label={`Amount paind in ${index + 1} times`}
                                                                        >
                                                                            {formatCurrency({ value: item?.installmentAmount })}
                                                                        </Text>
                                                                    </Flex>

                                                                    <Text
                                                                        fontSize=".9rem"
                                                                        color="newGray.400"
                                                                        fontWeight="400"
                                                                        aria-label={`Amount paind in ${index + 1} times`}
                                                                    >
                                                                        ({formatCurrency({ value: item?.totalAmount })})

                                                                    </Text>

                                                                </Flex>
                                                                {item?.discount > 0 && item?.discount !== null && (
                                                                    <Text
                                                                        fontSize=".9rem"
                                                                        color="#0AA17C"
                                                                        fontWeight="400"
                                                                        aria-label={`total discount`}
                                                                    >
                                                                        {item?.discount} % de desconto

                                                                    </Text>
                                                                )}
                                                            </Flex>

                                                            <Flex
                                                                w="70%"
                                                                gap="10px"
                                                            >
                                                                <Text
                                                                    fontSize=".9rem"
                                                                    color="newGray.400"
                                                                    fontWeight="400"
                                                                    aria-label={`Amount paind in ${index + 1} times`}
                                                                >
                                                                    1x
                                                                </Text>
                                                                <Text
                                                                    fontSize=".9rem"
                                                                    color="black"
                                                                    fontWeight="400"
                                                                    aria-label={`Amount paind in ${index + 1} times`}
                                                                >
                                                                    {formatCurrency({ value: previewData?.arrayInstallments.installmentsToSeller[0]?.receivedAmount })}
                                                                </Text>
                                                            </Flex>
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            )}

                                            {previewData?.creditCardTax === "EARLY_SELLER" && hasToogle === false && (
                                                <Flex flexDir="column"
                                                    gap=".5rem">

                                                    {previewData?.arrayInstallments?.installmentsToSeller?.map((item, index) => (
                                                        <Flex justifyContent="space-between"
                                                            key={index}  >
                                                            <Flex gap=".5rem" w="130%" >
                                                                <Flex flexDirection="column">
                                                                    <Flex gap="10px"  >
                                                                        <Text
                                                                            fontSize=".9rem"
                                                                            color="newGray.400"
                                                                            fontWeight="400"
                                                                            aria-label={`Amount paind in ${index + 1} times`}
                                                                        >
                                                                            {item?.installments}x
                                                                        </Text>
                                                                        <Text
                                                                            fontSize=".9rem"
                                                                            color="black"
                                                                            fontWeight="400"
                                                                            aria-label={`Amount paind in ${index + 1} times`}
                                                                        >
                                                                            {formatCurrency({ value: previewData?.arrayInstallments.installmentsToBuyer?.at(0)?.installmentAmount / (index + 1) })}
                                                                        </Text>
                                                                    </Flex>
                                                                    <Text
                                                                        fontSize=".9rem"
                                                                        color="newGray.400"
                                                                        fontWeight="400"
                                                                        aria-label={`Amount paind in ${index + 1} times`}
                                                                    >
                                                                    </Text>

                                                                </Flex>
                                                                {item?.discount >= 0 && (
                                                                    <Text
                                                                        fontSize=".9rem"
                                                                        color="#0AA17C"
                                                                        fontWeight="400"
                                                                        aria-label={`total discount`}
                                                                    >
                                                                        {item?.discount}% de desconto
                                                                    </Text>
                                                                )}
                                                            </Flex>

                                                            <Flex w="70%" gap="10px" >
                                                                <Text
                                                                    fontSize=".9rem"
                                                                    color="newGray.400"
                                                                    fontWeight="400"
                                                                    aria-label={`Amount paind in ${index + 1} times`}
                                                                >
                                                                    1x
                                                                </Text>
                                                                <Text
                                                                    fontSize=".9rem"
                                                                    color="black"
                                                                    fontWeight="400"
                                                                    aria-label={`Amount paind in ${index + 1} times`}
                                                                >
                                                                    {formatCurrency({ value: item?.receivedAmount })}

                                                                </Text>
                                                            </Flex>
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            )}

                                            {previewData?.creditCardTax === "EARLY_SELLER" && hasToogle === true && (
                                                <Flex gap=".5rem">

                                                    <Flex
                                                        w='85%'
                                                        height="89px"
                                                        border="1px solid #EDF1F7"
                                                        borderRadius="2px"
                                                        alignContent="center"
                                                        alignItems="center"
                                                        paddingLeft="16px"
                                                        fontSize="14px"
                                                        gap='3px'
                                                    >
                                                        <Text
                                                            fontWeight="600"
                                                            color="black"
                                                        >
                                                            até
                                                        </Text>
                                                        <Text>
                                                            {previewData?.arrayInstallments?.installmentsToSeller.length}x
                                                        </Text>
                                                    </Flex>
                                                    <Flex
                                                        w='30%'
                                                        height="89px"
                                                        alignContent="center"
                                                        alignItems="center"
                                                        paddingLeft="16px"
                                                    >
                                                        <Img w="37px" h="52px" src="/images/commons/ArrowToRight.svg" />

                                                    </Flex>
                                                    <Flex
                                                        w="85%"
                                                        height="89px"
                                                        border="1px solid #EDF1F7"
                                                        borderRadius="2px"
                                                        alignContent="center"
                                                        alignItems="center"
                                                        paddingLeft="16px"
                                                        gap="3px"
                                                    ><Text>
                                                            1x
                                                        </Text>
                                                        <Text
                                                            fontWeight="600"
                                                            color="black"
                                                        >
                                                            {formatCurrency({ value: previewData?.arrayInstallments?.installmentsToSeller.at(-1)?.receivedAmount })}

                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Flex>
                                )}




                                <Flex id="alert"
                                    backgroundColor="rgba(255, 251, 230, 1)"
                                    border="1px solid rgba(255, 241, 184, 1)"
                                    w="100%"
                                    h='89px'
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Text color="rgba(135, 77, 0, 1)" fontSize="14px" p="12px 14px 12px 12px">
                                        💡 A fim de otimizar suas taxas, selecione um parcelamento máximo que acredita que seu cliente irá usar
                                    </Text>
                                </Flex>

                            </Flex>
                        }

                        {
                            previewData?.enablePix &&

                            <React.Fragment>
                                <Divider />
                                <Flex id="pix" w="100%" justifyContent="space-between">
                                    <Flex flexDirection="column" fontWeight="600" lineHeight="22.38px" color="rgba(0, 0, 0, 1)" w="100%" gap="4px" >
                                        <Text>
                                            PIX
                                        </Text>


                                        {previewData?.pix?.installments >= 2 ?

                                            <Text fontSize="14px" fontWeight="400" color="rgba(110, 122, 145, 1)">
                                                1x a {previewData?.pix?.installments}x
                                            </Text> :
                                            <Text fontSize="14px" fontWeight="400" color="rgba(110, 122, 145, 1)">
                                                1x
                                            </Text>
                                        }

                                    </Flex>

                                    <Flex flexDirection="column" gap="4px" >

                                        <Text color="rgba(0, 0, 0, 1)" fontWeight="600">
                                            {valueDescontCredicard(previewData?.pix?.discount)}

                                        </Text>
                                        <Text fontSize="14px" fontWeight="400" color="rgba(110, 122, 145, 1)">
                                            Você recebe
                                        </Text>
                                        <Text fontSize="12px" color="black" w="160px" fontWeight="500">
                                            de forma recorrente de acordo com parcelamento
                                        </Text>



                                    </Flex>


                                </Flex>
                            </React.Fragment>

                        }

                        {
                            previewData?.enableBankSlip &&
                            <React.Fragment>
                                <Divider />

                                <Flex id="boleto" w="100%" justifyContent="space-between">
                                    <Flex flexDirection="column" fontWeight="600" lineHeight="22.38px" color="rgba(0, 0, 0, 1)" w="100%" gap="4px" >
                                        <Text>
                                            Boleto
                                        </Text>


                                        {previewData?.boleto?.installments >= 2 ?

                                            <Text fontSize="14px" fontWeight="400" color="rgba(110, 122, 145, 1)">
                                                1x a {previewData?.boleto?.installments}x
                                            </Text> :
                                            <Text fontSize="14px" fontWeight="400" color="rgba(110, 122, 145, 1)">
                                                1x
                                            </Text>
                                        }

                                    </Flex>

                                    <Flex flexDirection="column" gap="4px" >

                                        <Text color="rgba(0, 0, 0, 1)" fontWeight="600">
                                            {valueDescontCredicard(previewData?.boleto?.discount)}


                                        </Text>
                                        <Text fontSize="14px" fontWeight="400" color="rgba(110, 122, 145, 1)">
                                            Você recebe
                                        </Text>
                                        <Text fontSize="12px" color="black" w="160px" fontWeight="500">
                                            de forma recorrente de acordo com parcelamento
                                        </Text>



                                    </Flex>


                                </Flex>
                            </React.Fragment>

                        }

                    </Flex>


                }

            </Flex>

        </Flex >
    )
}
export default PreviewLinkPagamento;