import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Flex, Box, Link, Image, Text } from "@chakra-ui/react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { Filter, Avatar, Pagination } from "@/components";
import { WithAuth } from "@/hoc";
import { useSubscription } from "@/hooks";
import { Subscription, SubscriptionPaginator } from "@/models";
import {
  getPeriodicity,
  getStatus,
  formatCurrency,
  getColorBadgeStatus,
  formatDate,
  showToast,
} from "@/utils";

const Assinaturas = () => {
  const navigate = useNavigate();
  const { getSubscriptions } = useSubscription();
  const [data, setData] = useState({} as SubscriptionPaginator);
  const [tabStatus, setTabStatus] = useState("");
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  async function getSubscriptionsData(filter?: string) {
    setLoading(true)
    setFilterText(filter);
    try {
      const { data } = await getSubscriptions(
        {
          status: tabStatus === "ALL" ? "" : tabStatus,
          expirationDateInitial: startDate ? format(startDate, "yyyy-MM-dd") : "",
          expirationDateFinal: endDate ? format(endDate, "yyyy-MM-dd") : "",
          customerName: filter ? filter : "",
        },
        { size: 20, page: currentPage, sort: "startDate,DESC" }
      );

      setData(data);
    } catch (error) {

    } finally {
      setLoading(false)

    }
  }

  const tabs = [
    {
      tab: "Todas",
      key: "ALL",
      status: "ALL",
    },
    {
      tab: "Ativas",
      key: "ACTIVE",
      status: "ACTIVE",
    },
    {
      tab: "Inativas",
      key: "INACTIVE",
      status: "INACTIVE",
    },
    {
      tab: "Pendentes",
      key: "PENDING",
      status: "PENDING",
    },
    {
      tab: "Inadimplentes",
      key: "DEFAULTER",
      status: "DEFAULTER",
    },
  ];

  const { TabPane } = Tabs;

  const columns: ColumnsType<Subscription> = [
    {
      key: "customerName",
      title: "Cliente",
      dataIndex: "customerName",
      render: (customerName) => <Avatar customerName={customerName} />,
    },
    {
      key: "startDate",
      title: "Contratação",
      dataIndex: "startDate",
      render: (startDate) => formatDate(startDate),
    },

    {
      key: "titlePlan",
      title: "Plano",
      dataIndex: "titlePlan",
    },

    {
      key: "type",
      title: "Periodicidade ",
      dataIndex: ["basicValue", "type"],
      render: (type) => getPeriodicity(type),
    },

    {
      key: "value",
      title: "Valor/mês",
      dataIndex: ["basicValue", "value"],
      render: (value) => formatCurrency({ value }),
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status ? (
          <Badge fontSize="1rem" variant={getColorBadgeStatus(status)}>
            {getStatus(status)}
          </Badge>
        ) : (
          "-"
        ),
    },
    {
      key: "buttonOptions",
      title: "",
      render: (text, record) => (
        <Box p="0" w="200px" h="30px">
          <Flex
            align="center"
            justify="space-between"
            className="rowAction"
            w="170px"
            h="30px"
          >
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="190px"
              bgColor="white"
              borderRadius="8px"
              zIndex="99"
              transition="all .2s"
              p=" 0 8px"
              _hover={{
                transition: "all .2s",
                bgColor: "white",
              }}
            >

              <Link
                onClick={(event) => event.stopPropagation()}
                href={`/recorrencias/${record.uuid}`}
                target="_blank"
                _hover={{ cursor: "pointer" }}
                display="flex"
                alignItems="center"
                gap="10px"
              >

                <Image
                  id="excluir_cobranca"
                  h="20px"
                  w="20px"
                  src="/images/icons/Export.svg"
                  transition="filter .2s"
                  _hover={{
                    transition: "filter .2s",
                    filter: "brightness(.3)",
                  }}
                />

                <Text fontSize="12px" color="gray_600">
                  Abrir em nova aba
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (tabStatus) {
      getSubscriptionsData(filterText);
    }
  }, [tabStatus, currentPage]);

  useEffect(() => {
    getSubscriptionsData();
  }, [currentPage]);

  return (
    <Box letterSpacing="tighter">
      <Filter
        placeholder="Buscar Recorrências"
        searchFunction={getSubscriptionsData}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        isVisibleDatepicker={true}
      />

      <Tabs
        defaultActiveKey="1"
        className="tabs-custom"
        onChange={(activeKey) => {
          setCurrentPage(0);
          setTabStatus(activeKey);
        }}
      >
        {tabs.map(({ key, tab }) => (
          <TabPane tab={tab} key={key}>
            <Table<Subscription>
              columns={columns}
              dataSource={
                data?.content?.length > 0 ? data.content : data.content
              }
              onRow={(record) => {
                return {
                  onClick: () => navigate(`/recorrencias/${record.uuid}`),
                };
              }}
              rowKey={(record) => record.uuid}
              locale={{ emptyText: "Sem dados de recorrências" }}
              pagination={false}
            />
            <Pagination
              totalPages={data?.totalPages}
              setCurrentPage={setCurrentPage}
            />
          </TabPane>
        ))}
      </Tabs>
    </Box>
  );
};

export default WithAuth(Assinaturas);
