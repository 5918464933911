import Login from "@/pages/login";
import ResetPassword from "@/pages/redefinir-senha";
import ResetPasswordDetails from "@/pages/redefinir-senha/id";
import SejaPago from "@/pages/seja-pago";
import CustomLinks from "@/pages/links-customizados";
import CustomLinksEdit from "@/pages/links-customizados/id";
import Assinaturas from "@/pages/assinaturas";
import AssinaturaEdit from "@/pages/assinaturas/id";
import Clientes from "@/pages/clientes";
import ClientesDetails from "@/pages/clientes/id";
import AdicionarCliente from "@/pages/clientes/adicionar-cliente";
import CobrancasHistorico from "@/pages/cobrancas-historico";
import CobrancaDetalhes from "@/pages/cobrancas-historico/id";
import CobrancasAgendadas from "@/pages/cobrancas-agendadas";
import CobrancaAgendadaDetalhes from "@/pages/cobrancas-agendadas/id";
import MyPlans from "@/pages/meu-checkout/meus-planos";
import PlanPage from "@/pages/meu-checkout/meus-planos/id";
import MeuCheckout from "@/pages/meu-checkout/";
import MeuCheckoutInfo from "@/pages/meu-checkout/checkout-info";
import Register from "@/pages/cadastro";
import Notifications from "@/pages/configuracoes/notifications";
import PaymentMethods from "@/pages/configuracoes/metodos-pagamentos";
import NotaFiscal from "@/pages/configuracoes/nota-fiscal";
import Integracao from "@/pages/configuracoes/integracao";
import SubSellers from "@/pages/configuracoes/subsellers";
import NewDashboard from "@/pages/new-dashboard";
import Resgates from "@/pages/resgates";
import SignatureImport from "@/pages/seja-pago/signature-import";
import ContasPagar from "./pages/contas-a-pagar";
import NewSubSeller from "./pages/configuracoes/subsellers/NewSubSeller";
import SubSeller from "./pages/configuracoes/subsellers/id";
// import PjAccount from "./pages/configuracoes/ativar-conta-pj"
import Relatorios from "./pages/relatorios";

let pathname = window.location.pathname;
const user = JSON.parse(localStorage.getItem("barte-user-info"));
export const RoutesList = [
  {
    key: 1,
    path: "/",
    title: "Início",
    breadcrumb: "Início",
    icon: "home_outline.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: false,
    exact: true,
    element: <NewDashboard />,
    feature: "view_seller_home",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_DASHBOARD"],
  },
  {
    key: 2,
    title: "",
    breadcrumb: "",
    path: "/login",
    exact: true,
    element: <Login />,
    feature: "",
    role: [],
  },
  {
    key: 99,
    title: "",
    breadcrumb: "",
    path: "/314119201815",
    exact: true,
    element: <Register />,
    feature: "",
    role: [],
  },
  {
    key: 4,
    title: "",
    breadcrumb: "",
    path: "/redefinir-senha",
    exact: true,
    element: <ResetPassword />,
    feature: "",
    role: [],
  },
  {
    key: 5,
    title: "",
    breadcrumb: "",
    path: "/redefinir-senha/:id",
    exact: true,
    element: <ResetPasswordDetails />,
    feature: "",
  },
  {
    key: 6,
    title: "Criar",
    breadcrumb: "Criar",
    path: "/seja-pago/criar/:id",
    exact: true,
    element: <SejaPago />,
    icon: "plus.svg",
    feature: "view_seller_create_payment_link",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_PAYMENT_LINK"],
  },
  {
    key: 7,
    path: "/seja-pago",
    title: "Links de pagamento",
    breadcrumb: "Links de pagamento",
    icon: "plus.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: false,
    exact: true,
    element: <CustomLinks />,
    feature: "view_seller_payment_link",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_PAYMENT_LINK"],
  },
  {
    key: 8,
    title: "Editar",
    breadcrumb: "Editar",
    path: "/seja-pago/editar/:id",
    exact: true,
    element: <CustomLinksEdit />,
    feature: "view_seller_payment_link_edit",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_PAYMENT_LINK"],
  },
  {
    key: 32,
    title: "Link Rápido",
    breadcrumb: "Link Rápido",
    path: "/seja-pago/link-rapido",
    exact: true,
    element: <SejaPago />,
    feature: "view_checkout_create_order_by_fast_link",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_PAYMENT_LINK"],
  },
  {
    key: 9,
    title: "Importação de Recorrência",
    breadcrumb: "Importação de recorrências",
    path: "/importacao-de-recorrencia",
    exact: true,
    element: <SignatureImport />,
    icon: "plus.svg",
    feature: "view_seller_import_payment_link",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_SUBSCRIPTIONS"],
  },
  {
    key: 10,
    path: "",
    detail: "/meu-checkout",
    title: "Meu Checkout",
    breadcrumb: "Meu Checkout",
    icon: "shopping_cart.svg",
    isShowMenu: true,
    defaultIsOpen:
      pathname.includes("/checkout-info") || pathname.includes("/meus-planos"),
    exact: true,
    element: <MeuCheckout />,
    feature: "view_seller_checkout",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHECKOUT"],
    items: [
      {
        key: 11,
        path: "/checkout-info",
        title: "Informações gerais",
        breadcrumb: "Informações gerais",
        exact: true,
        isShowMenu: true,
        element: <MeuCheckoutInfo />,
        feature: "view_seller_info_default",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHECKOUT"],
        icon: "shopping_cart.svg",
      },
      {
        key: 12,
        path: "/meus-planos",
        title: "Meus planos",
        breadcrumb: "Meus planos",
        exact: true,
        isShowMenu: true,
        element: <MyPlans />,
        feature: "view_seller_plans",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHECKOUT"],
        icon: "shopping_cart.svg",
      },
    ],
  },
  {
    key: 13,
    title: "Criar ou Editar",
    breadcrumb: "Criar ou Editar",
    path: "/meus-planos/:id",
    exact: true,
    element: <PlanPage />,
    feature: "view_seller_plans_create",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHECKOUT"],
  },
  {
    key: 14,
    path: "",
    detail: "/recebiveis",
    title: "Cobranças",
    breadcrumb: "Cobranças",
    icon: "receive.svg",
    isShowMenu: true,
    defaultIsOpen:
      pathname.includes("/cobrancas-historico") ||
      pathname.includes("/cobrancas-agendadas"),
    isNew: false,
    feature: "view_seller_charges",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHARGES"],
    items: [
      {
        key: 15,
        path: "/cobrancas-historico",
        title: "Histórico",
        breadcrumb: "Histórico de Cobranças",
        exact: true,
        isShowMenu: true,
        element: <CobrancasHistorico />,
        feature: "view_seller_charges_history",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHARGES"],
        icon: "receive.svg",
      },
      {
        key: 16,
        path: "/cobrancas-agendadas",
        title: "Agendadas",
        breadcrumb: "Agendadas",
        exact: true,
        isShowMenu: true,
        element: <CobrancasAgendadas />,
        feature: "view_seller_charges_late",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHARGES"],
        icon: "receive.svg",
      },
    ],
  },
  {
    key: 18,
    title: "Detalhes",
    breadcrumb: "Detalhes",
    path: "/cobrancas-historico/:id",
    exact: true,
    element: <CobrancaDetalhes />,
    feature: "view_seller_charges_history_details",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHARGES"],
  },
  {
    key: 19,
    title: "Detalhes",
    breadcrumb: "Detalhes",
    path: "/cobrancas-agendadas/:id",
    exact: true,
    element: <CobrancaAgendadaDetalhes />,
    feature: "view_seller_charges_late_details",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CHARGES"],
  },
  {
    key: 20,
    path: "/contas-a-pagar",
    title: "Contas a Pagar",
    breadcrumb: "Contas a Pagar",
    icon: "receive-out.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: true,
    exact: true,
    element: <ContasPagar />,
    feature: "view_accounts_payable",
    role: ["SELLER_MASTER","SELLER_BACKOFFICE", "SELLER_SUBSCRIPTIONS","SELLER_ADMIN"],
  },
  {
    key: 21,
    path: "/recorrencias",
    title: "Recorrências",
    breadcrumb: "Recorrências",
    icon: "subscription.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: false,
    exact: true,
    element: <Assinaturas />,
    feature: "view_seller_subscription",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_SUBSCRIPTIONS"],
  },
  {
    key: 22,
    title: "Detalhes",
    breadcrumb: "Detalhes",
    path: "/recorrencias/:id",
    exact: true,
    element: <AssinaturaEdit />,
    feature: "view_seller_subscription_details",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_SUBSCRIPTIONS"],
  },
  // {
  //   key: 22,
  //   path: "/metricas",
  //   title: "Métricas",
  //   breadcrumb: "Métricas",
  //   icon: "chart.svg",
  //   isShowMenu: true,
  //   defaultIsOpen: false,
  //   isNew: false,
  //   exact: true,
  //   element: <Dashboard />,
  //   feature: "view_seller_metrics",
  // },
  {
    key: 23,
    path: "/clientes",
    title: "Clientes",
    breadcrumb: "Clientes",
    icon: "id.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: false,
    exact: true,
    element: <Clientes />,
    feature: "view_seller_clients",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CLIENTS"],
  },
  {
    key: 24,
    title: "Adicionar cliente",
    breadcrumb: "Adicionar cliente",
    path: "/clientes/adicionar-cliente",
    exact: true,
    element: <AdicionarCliente />,
    feature: "view_seller_clients_add",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CLIENTS"],
  },
  {
    key: 25,
    title: "Editar cliente",
    breadcrumb: "Editar cliente",
    path: "/clientes/:id",
    exact: true,
    element: <ClientesDetails />,
    feature: "view_seller_clients_edit",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CLIENTS"],
  },
  {
    key: 26,
    path: "",
    detail: "/configuracoes",
    title: "Configurações",
    breadcrumb: "Configurações",
    icon: "settings.svg",
    isShowMenu: true,
    defaultIsOpen:
      pathname.includes("/notificacoes") ||
      pathname.includes("/metodos-pagamentos") ||
      pathname.includes("/nota-fiscal") ||
      pathname.includes("/integracao") ||
      pathname.includes("/sub-vendedores"),
    isNew: false,
    feature: "view_seller_configurations",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_PAYMENT_METHODS", "SELLER_CONFIG_NF", "SELLER_CONFIG_INTEGRATION", "SELLER_CONFIG_NOTIFICATION"],
    items: [
      // {
      //   key: 27,
      //   path: "/ativar-pj",
      //   title: "Ativar Conta PJ",
      //   breadcrumb: "Ativar Conta PJ",
      //   exact: true,
      //   isShowMenu: true,
      //   element: <PjAccount />,
      //   feature: "view_seller_notifications",
      //   role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_NOTIFICATION"],
      //   icon: "settings.svg",
      // },
      {
        key: 28,
        path: "/notificacoes",
        title: "Notificações",
        breadcrumb: "Notificações",
        exact: true,
        isShowMenu: true,
        element: <Notifications />,
        feature: "view_seller_notifications",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_NOTIFICATION"],
        icon: "settings.svg",
      },
      {
        key: 29,
        path: "/metodos-pagamentos",
        title: "Métodos de Pagamento",
        breadcrumb: "Métodos de Pagamento",
        exact: true,
        isShowMenu: true,
        element: <PaymentMethods />,
        feature: "view_seller_payment_method",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_PAYMENT_METHODS"],
        icon: "settings.svg",
      },
      {
        key: 30,
        path: "/nota-fiscal",
        title: "Nota fiscal",
        breadcrumb: "Nota fiscal",
        exact: true,
        isShowMenu: true,
        element: <NotaFiscal />,
        feature: "view_seller_nf",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_NF"],
        icon: "settings.svg",
      },
      {
        key: 31,
        path: "/integracao",
        title: "Integração",
        breadcrumb: "Integração",
        exact: true,
        isShowMenu: true,
        element: <Integracao />,
        feature: "view_seller_integration",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_INTEGRATION"],
        icon: "settings.svg",
      },
      {
        key: 32,
        path: "/sub-vendedores",
        title: "Sub-vendedores",
        breadcrumb: "Sub-vendedores",
        exact: true,
        isShowMenu: true,
        element: <SubSellers />,
        feature: "view_seller_sub_seller",
        role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_INTEGRATION"],
        icon: "settings.svg"
      }
    ],
  },
  {
    key: 33,
    path: "/financeiro",
    title: "Financeiro",
    breadcrumb: "Financeiro",
    icon: "wallet.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: false,
    exact: true,
    element: <Resgates />,
    feature: "view_seller_finance",
    role: ["SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_FINANCES"],
  },
  {
    key: 33,
    path: "/reports",
    title: "Relatórios",
    breadcrumb: "Relatórios",
    icon: "report.svg",
    isShowMenu: true,
    defaultIsOpen: false,
    isNew: false,
    exact: true,
    element: <Relatorios />,
    feature: "view_seller_reports",
    role: ["SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_FINANCES"],
  },
  {
    key: 34,
    path: "/sub-vendedores/novo",
    title: "Novo sub-vendedor",
    breadcrumb: "Novo sub-vendedor",
    exact: true,
    element: <NewSubSeller />,
    icon: "settings.svg",
    feature: "view_seller_sub_seller",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_INTEGRATION"],
  },
  {
    key: 35,
    path: "/sub-vendedores/:id",
    title: "Detalhes",
    breadcrumb: "Detalhes",
    exact: true,
    element: <SubSeller />,
    icon: "settings.svg",
    feature: "view_seller_sub_seller",
    role: ["SELLER_MASTER", "SELLER_ADMIN", "SELLER_BACKOFFICE", "SELLER_CONFIG_INTEGRATION"],
  },
];

export function flatRoutesList() {
  let flatItems = RoutesList.filter((item) => item.items)
    .map((item) => item.items)
    .flat();
  return [...RoutesList, ...flatItems];
}

const flatRoutes = flatRoutesList()
