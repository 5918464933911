import { Image } from "@chakra-ui/react";

const ScheduledTotalIcon = () => {
  return(
    <Image
      maxW="20px"
      src="/images/icons/scheduledTotalIcon.svg"
      transition="filter .2s"
    />
  )
}

export { ScheduledTotalIcon }