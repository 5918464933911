import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { AuthContext, signOut } from "@/contexts";
import styles from "./styles.module.scss";
import { RoutesInterface } from "@/models";
import { getApplicationName, isExactFeatureToggle, isExactRole } from "@/utils";
import { RoutesList } from "@/Routes";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const featureTogglesUser = JSON.parse(localStorage.getItem("barte-features-list-user"));
  const [defaultIndex, setDefaultIndex] = useState();
  const [routesApp, setRoutesApp] = useState<RoutesInterface[]>([]);
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const applicationName = getApplicationName({});
  const Logout = (e) => {
    e.preventDefault();
    signOut();
    navigate("/login");
  };
  const exactMatchFeatureToggles = (item) => item.isShowMenu && isExactRole(user?.roles, item?.role) && isExactFeatureToggle(featureTogglesUser, item?.feature);
  const getRoutesFiltered = (arr) => {
    return arr.reduce((acc, item) => {
      const newItem = item;
      if (item?.items) {
        newItem.items = getRoutesFiltered(item?.items);
      }
      if (exactMatchFeatureToggles(newItem)) {
        acc.push(newItem);
      }
      return acc;
    }, []);
  };

  const getDefaultIndex = (RoutesFiltered) => {
    return RoutesFiltered.filter((item) => item.isShowMenu).reduce(
      (acc, { defaultIsOpen }, index) => {
        if (defaultIsOpen) {
          acc.push(index);
        }
        return acc;
      },
      [] as number[]
    );
  };
  useEffect(() => {
    let RoutesFiltered = getRoutesFiltered(RoutesList);
    setRoutesApp(RoutesFiltered);
    let defaultIndexFiltered = getDefaultIndex(RoutesFiltered);
    setDefaultIndex(defaultIndexFiltered);
  }, []);
  return (
    <Box
      borderRight="1px"
      borderColor="#f0f0f0"
      position="relative"
      w={{ base: "230px", "2xl": "270px" }}
      
    >
        <Flex
          position="fixed"
          gridArea="S"
          borderRight="1px"
          borderColor="#f0f0f0"
          height="100vh"
          direction="column"
          py={6}
          bgColor="gray.24"
          w={{ base: "230px", "2xl": "270px" }}
          overflow="hidden"
        >
          <Box m={6}>
            <img
              src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`}
              alt="Logo"
              width="120px"
              height="50px"
            />
          </Box>
          <Accordion allowMultiple>
            {routesApp
              ?.filter((item) => item?.isShowMenu)
              .map(({ key, icon, path, detail, title, items, isNew }) => (
                <AccordionItem
                  p="0 .4rem"
                  border="none"
                  my={3}
                  key={key}
                  _hover={{
                    fontcolor: "black",
                    color: "black",
                  }}
                >

                  <AccordionButton
                    id={key === 14 ? "click_menu_antecipar" : ""}
                    borderRadius="5"
                    bgColor={pathname === path ? "gray.50" : pathname}
                    onClick={() => navigate(path)}
                    _hover={{
                      backgroundColor: "gray.50",
                      fontcolor: "black",
                      color: "black",
                    }}
                  >
                    <Flex
                      key={title}
                      align={"center"}
                      gridGap="2"
                      w="100%"
                      minW="270px"
                      _hover={{
                        fontcolor: "black",
                        color: "black",
                      }}
                    >
                      
                      <img
                        src={`/images/icons/${icon}`}
                        className="ant-menu-item-icon"
                        width="25"
                        height="25"
                      />
                      {path ? (
                        <Link to="#">
                          <Flex gap="2">
                            <Text
                              color={
                                (pathname === path ||
                                  pathname.startsWith(detail)) &&
                                "black"
                              }
                            >
                              {title}
                            </Text>
                            {isNew && <Text padding="0px 6px 0px 6px" fontWeight="400" borderRadius="10px" lineHeight="24px" fontSize="10px" backgroundColor="rgba(195, 202, 215, 1)" color="black">beta</Text>}
                          </Flex>
                        </Link>
                      ) : (
                        <Flex gap="1">
                          <Text
                            color={
                              (pathname === path ||
                                pathname.startsWith(detail)) &&
                              "black"
                            }
                            _hover={{
                              fontcolor: "black",
                              color: "black",
                            }}
                          >
                            {title}
                          </Text>
                          {isNew && <Text color="green.300">(novo)</Text>}
                        </Flex>
                      )}

                      {items && <AccordionIcon />}
                    </Flex>
                  </AccordionButton>
                  {items && (
                    <>
                      {items.map(({ key, path, title }) => (
                        <AccordionPanel
                          borderRadius="5"
                          m=".2rem 0"
                          key={key}
                          py={2}
                          bgColor={
                            pathname.includes(path) ? "gray.50" : pathname
                          }
                          onClick={() => navigate(path)}
                          _hover={{
                            backgroundColor: "gray.50",
                            cursor: "pointer",
                            fontcolor: "black",
                            color: "black",
                          }}
                        >
                          <Link to="#">
                            <Flex align="center" w="100%">
                              <Box
                                borderRadius="full"
                                w="6px"
                                h="6px"
                                bg="gray.300"
                                mr="4"
                                _hover={{
                                  fontcolor: "black",
                                  color: "black",
                                }}
                              />
                              <Text>{title}</Text>
                            </Flex>
                          </Link>
                        </AccordionPanel>
                      ))}
                    </>
                  )}
                </AccordionItem>
              ))}
          </Accordion>
          <Spacer />
          <Accordion>
            <AccordionItem border="none" w="100%">
              <AccordionButton onClick={(e) => Logout(e)}>
                <Flex
                  align={"center"}
                  gridGap="2"
                  // p={2}
                >
                  <MdOutlineLogout
                    size={23}
                    className={`${pathname == "/" && styles.navActive} ${
                      styles.navLink
                    }`}
                  />
                  <Link to="#">
                    Sair
                  </Link>
                </Flex>
              </AccordionButton>
            </AccordionItem>
          </Accordion>
        </Flex>
    </Box>
  );
};
export { Sidebar };
