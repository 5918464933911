import { Badge, ChakraProvider, Flex, Text, Button, UnorderedList, ListItem, Img, useTheme, Divider } from "@chakra-ui/react";
import {
    getColorsBillsStatus,
    formatDate,
} from "@/utils";
import { useEffect, useState } from "react";
import { Input } from "@/components";
import "./contasPagar.scss"
import { useBillsToPay } from "@/hooks/useBillsToPay";
import { showToast } from "@/utils";
import { paymentForecast } from "@/utils/paymentForecast";
import { BillsToPay } from "@/models";
import { BillsData } from "@/models/BillsData";
import { nextDayOfPaymentWeek } from "@/utils/smallerNextDay";

interface editeBills {
    setOpenEditeBills?: React.Dispatch<React.SetStateAction<boolean>>
    setHasNewEdit?: React.Dispatch<React.SetStateAction<boolean>>
    billsData?: BillsData;
    dayOfPayment?: Date;
}

export function ModalEditeBills({ setOpenEditeBills, setHasNewEdit, billsData, dayOfPayment }: editeBills) {
    const theme = useTheme();
    const statusColor = theme?.components?.Badge?.variants[getColorsBillsStatus(billsData?.Status?.replace(" ", ""))]?.color;
    const [editBills, setEditBills] = useState<boolean>(false)
    const [arquivoSelecionado, setArquivoSelecionado] = useState("");
    const [newBillsData, setNewBillsData] = useState<BillsData>();
    const [newFileData, setNewFileData] = useState<any>();
    const { updateBills, deleteBills, getOneBills } = useBillsToPay();
    const [confirmUpdateBills, setConfirmUpdateBills] = useState<boolean>(false);
    const [confirmUpdateBillsAgain, setConfirmUpdateBillsAgain] = useState<boolean>(false);
    const [newDataObject, setNewDataObject] = useState<BillsData>(billsData)
    const [dateScheduling, setDateScheduling] = useState<string>()

    function paymentMethod(billsData) {
        if (billsData["Método"] === "Boleto") {
            return billsData["Boleto - Código"]
        }
        if (billsData["Método"] === "Pix") {
            return billsData["Pix - Chave Pix"] || billsData["Pix - Copia e Cola"]
        }
    }

    function paymentMethodEdit(billsData) {

        const teste = "Pix - Copia e Cola"
        const teste2 = "Pix - Chave Pix"

        if (billsData["Método"] === "Boleto") {
            return "Boleto - Código"
        }
        if (billsData["Método"] === "Pix") {

            if (billsData.hasOwnProperty(teste2)) {
                return "Pix - Chave Pix"
            }
            if (billsData.hasOwnProperty(teste)) {
                return "Pix - Copia e Cola"
            }
        }
    }

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileObject = {
                id: billsData.id,
                url: "https://airtable.com/appgcCA1WiWUpKMVc/tbl9TukvdgqUfl8JX/viwUU6i1z9jZ5KLXd/rec0MNdWuStxKCkgK/fldeRcIsWt7eNQWk2",
                filename: file.name,
                size: file.size,
                type: file.type,
            };
            setNewFileData(
                fileObject
            );

            setArquivoSelecionado(file.name)

            const formData = new FormData();
            formData.append("arquivo", file);

        }
    };

    const handleChange = (name, value) => {

        if (name === "Valor") {
            const valorNumerico = parseFloat(value.replace(/[^0-9,]/g, '').replace(',', '.'));
            setNewBillsData(
                { ...newBillsData, "Valor": valorNumerico }
            )

        }
        if (name === "Vencimento") {
            const partes = value.split('/');
            const formatedDate = `${partes[2]}/${partes[1]}/${partes[0]}`;
            setNewBillsData(
                { ...newBillsData, "Vencimento": formatedDate }
            )

        }

        else {
            setNewBillsData({
                ...newBillsData,
                [name]: value,
            });
        }

    }
    async function editeAndSendForAprove() {

        if (newBillsData.Valor) {
            const valorString = String(newBillsData.Valor);
            const valorNumerico = parseFloat(valorString.replace("R$ ", "").replace(".", "").replace(",", "."));
            const dataObject = {
                id: billsData?.id,
                fields: {
                    ...newBillsData,
                    "Status": 'Aprovar',
                    "Valor": valorNumerico,
                    "Agendado": dateScheduling

                }
            }


            try {
                const response = await updateBills({ records: [dataObject] })
                setEditBills(false)
                setConfirmUpdateBillsAgain(false)
                setOpenEditeBills(false)
                setConfirmUpdateBills(false)
                getOneBillsFunction()
                setHasNewEdit(true)
            } catch (error) {
                return error;
            }

        } else {
            const dataObject = {
                id: billsData?.id,
                fields: {
                    "Status": 'Aprovar',
                    "Agendado": dateScheduling
                }
            }

            try {
                const response = await updateBills({ records: [dataObject] })
                setEditBills(false)
                setConfirmUpdateBillsAgain(false)
                setConfirmUpdateBills(false)
                setOpenEditeBills(false)
                getOneBillsFunction()
                setHasNewEdit(true)
            } catch (error) {
                return error;
            }
        }
        setHasNewEdit(true)

    }

    async function updateCancelBills(recordId: string) {
        const dataObject = {
            'id': recordId, 'fields': {
                'Status': "Cancelada"
            }
        }
        try {
            const reprovePromisses = await updateBills({ records: [dataObject] })
            if (reprovePromisses.status === 200) {
                setEditBills(false)
                setOpenEditeBills(false)
                setHasNewEdit(true)
                getOneBillsFunction()
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getOneBillsFunction() {
        try {
            const response = await getOneBills(billsData.id)
            setNewDataObject(response?.data?.fields)
        } catch (error) {
            console.log(error)
        }
    }

    async function AproveOrReprove(status: string) {

        const dataObject = {
            'id': billsData.id, 'fields': {
                'Status': status,
                "Agendado": paymentForecast({ dataBills: new Date(billsData?.Vencimento), newBills: true })
            }
        };

        console.log(dataObject)
        try {
            const response = await updateBills({ records: [dataObject] })
            setHasNewEdit(true)
            setOpenEditeBills(false)

        } catch (error) {
            return error;
        }
    }

    async function editeAndSaveBills() {
        try {
            let schedulingDate;

            if (newBillsData.Vencimento) {
                schedulingDate = await paymentForecast({ dataBills: new Date(newBillsData.Vencimento) });
            } else {
                schedulingDate = await paymentForecast({ dataBills: new Date(billsData?.Vencimento) });
            }

            setDateScheduling(schedulingDate);

            if (newBillsData.Valor) {
                const valorString = String(newBillsData.Valor);
                const valorNumerico = parseFloat(valorString.replace("R$ ", "").replace(".", "").replace(",", "."));
                const dataObject = {
                    id: billsData?.id,
                    fields: {
                        ...newBillsData,
                        "Valor": valorNumerico,
                        "Agendado": schedulingDate
                    }
                }

                try {
                    const response = await updateBills({ records: [dataObject] })
                    setEditBills(false)
                    setConfirmUpdateBillsAgain(false)
                    setConfirmUpdateBills(false)
                    getOneBillsFunction()
                    setHasNewEdit(true)

                } catch (error) {
                    showToast({
                        type: "error",
                        message: "Não foi possivel salvar alteração, verifique os valores dos campos.",
                    });
                    setConfirmUpdateBills(false)

                    return error;
                }

            } else {
                const dataObject = {
                    id: billsData?.id,
                    fields: {
                        ...newBillsData,
                        "Agendado": schedulingDate
                    }
                }

                try {
                    const response = await updateBills({ records: [dataObject] })
                    setEditBills(false)
                    setConfirmUpdateBillsAgain(false)
                    setConfirmUpdateBills(false)
                    getOneBillsFunction()
                    setHasNewEdit(true)

                } catch (error) {
                    showToast({
                        type: "error",
                        message: "Não foi possivel salvar alteração, verifique os valores dos campos.",
                    });

                    setConfirmUpdateBills(false)
                    return error;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    function saveBills() {
        const today = new Date()
        if (today.getHours() >= 10) {
            setConfirmUpdateBills(true)
        } else {
            editeAndSaveBills()
        }
    }
    return (

        <Flex position="absolute" top="0" left="0" zIndex={999} h="100%" w="100%"
            justifyContent="end"
            alignContent="end"
            alignItems="center"
            bg="rgb(0,0,0, 0.62)"

        >
            <Flex h="100%" w="582px" flexDirection="column"
                border="1px solid rgba(0, 0, 0, 0.06)"
                alignContent="center"
                alignItems="center"
                p="24px"
                bg="white"

            >

                <Flex justifyContent="space-between" w="100%" >
                    <Flex fontSize="14px" gap="8px">
                        <Text>
                            {newDataObject?.Status}
                        </Text>
                        <Badge
                            fontSize="1rem"
                            variant={getColorsBillsStatus(newDataObject?.Status?.replace(" ", ""))}
                            borderRadius="36px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Flex w="8px" h="8px" borderRadius="36px" backgroundColor={statusColor} />
                            <UnorderedList listStyleType="none" >
                                <ListItem fontSize="12px">{newDataObject?.Status}</ListItem>
                            </UnorderedList>
                        </Badge>
                    </Flex>
                    <Img w="24px" h="24px"
                        src="/images/icons/CloseOutlined.svg"
                        onClick={() => setOpenEditeBills(false)}
                        cursor="pointer"
                    />
                </Flex>

                <Divider borderColor="rgba(0, 0, 0, 0.06)" m="18px 0 25px 0" />

                <Flex
                    justifyContent="space-between"
                    w="100%"

                >
                    <Flex
                        flexDirection="column"
                    >
                        <Text
                            fontSize="21px"
                            fontWeight="600"
                            color="rgba(0, 0, 0, 1)"
                        >
                            {newDataObject["Nome da Conta"]}
                        </Text>
                        <Text
                            color="rgba(138, 148, 168, 1)"
                            fontSize="10px"
                            fontWeight="400"
                            lineHeight="14px"
                        >
                            ID {newDataObject?.ID}
                        </Text>

                    </Flex>


                    {!editBills && (billsData.Status !== "Paga" && billsData.Status !== "Cancelada") && (
                        <Button
                            backgroundColor="white"
                            border="1px solid rgba(0, 0, 0, 0.15)"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="22px"
                            height="32px"
                            onClick={() => setEditBills(true)}
                        >
                            Editar informações
                        </Button>
                    )}



                    {
                        editBills === true && (
                            <Flex
                                gap="12px"
                                justifyContent="flex-end"
                            >
                                <Button
                                    backgroundColor="white"
                                    border="1px solid rgba(0, 0, 0, 0.15)"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    w="82px"
                                    height="32px"
                                    onClick={() => setEditBills(false)}
                                >
                                    Cancelar
                                </Button>


                                {
                                    (billsData.Status === "Pagamento agendado" ||
                                        billsData.Status === "Aprovado")
                                    && (

                                        <Button
                                            disabled={newBillsData === undefined}
                                            backgroundColor="rgba(24, 144, 255, 1)"
                                            color="rgba(255, 255, 255, 1)"
                                            w="71"
                                            h="32px"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            height="32px"
                                            onClick={() => setConfirmUpdateBillsAgain(true)}
                                        >
                                            Salvar
                                        </Button>

                                    )}
                                {
                                    billsData.Status === "Aprovar"

                                    && (

                                        <Button
                                            disabled={newBillsData === undefined}

                                            backgroundColor="rgba(24, 144, 255, 1)"
                                            color="rgba(255, 255, 255, 1)"
                                            w="71"
                                            h="32px"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            height="32px"
                                            onClick={() => saveBills()}
                                        >
                                            Salvar
                                        </Button>

                                    )}


                                {
                                    (billsData.Status === "Reprovado" ||
                                        billsData.Status === "Atualização necessária" ||
                                        billsData.Status === "Erro no pagamento" ||
                                        billsData.Status === "Saldo insuficiente")
                                    && (

                                        <Button
                                            disabled={newBillsData === undefined}

                                            backgroundColor="rgba(24, 144, 255, 1)"
                                            color="rgba(255, 255, 255, 1)"
                                            w="71"
                                            h="32px"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            height="32px"
                                            onClick={() => saveBills()}
                                        >
                                            Salvar
                                        </Button>

                                    )}

                            </Flex>

                        )
                    }
                </Flex>

                <Flex
                    mt="50px"
                    gap="17px"
                    flexDirection="column"
                    w="100%"
                >

                    <Flex
                        justifyContent="space-between"
                        w="100%"

                    >
                        <Text
                            fontSize="14px"
                            color="rgba(138, 148, 168, 1)"
                        >
                            Beneficiário
                        </Text>
                        <Text
                            w="363px"
                            color="black"
                            fontWeight="400"
                            fontSize="14px"

                        >


                            {newDataObject["Beneficiário"] ? newDataObject["Beneficiário"] : ""}
                        </Text>
                    </Flex>
                    <Flex
                        justifyContent="space-between"
                        w="100%"
                        h="32px"

                    >
                        <Text
                            fontSize="14px"
                            color="rgba(138, 148, 168, 1)"
                        >
                            Vencimento
                        </Text>
                        {editBills === false ? (

                            <Text
                                w="363px"
                                color="black"
                                fontWeight="400"
                                fontSize="14px"

                            >
                                {formatDate(newDataObject["Vencimento"])}
                            </Text>


                        ) : (
                            <Flex
                                w="363px"
                                h="32px"
                            >
                                <Input
                                    mask="fullDate"
                                    onChange={(e) => handleChange("Vencimento", e.target.value)}
                                    type="text"
                                    name="Vencimento"
                                    defaultValue={formatDate(newDataObject["Vencimento"])}
                                />
                            </Flex>
                        )}
                    </Flex>
                    <Flex
                        justifyContent="space-between"
                        w="100%"

                    >
                        <Text
                            fontSize="14px"
                            color="rgba(138, 148, 168, 1)"
                        >
                            Valor
                        </Text>
                        {editBills === false ? (

                            <Text
                                w="363px"
                                color="black"
                                fontWeight="400"
                                fontSize="14px"

                            >
                                {newDataObject["Valor"]?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                            </Text>

                        ) : (<Flex
                            w="363px"
                            h="32px"
                        >
                            <Input
                                onChange={(e) => handleChange("Valor", e.target.value)}

                                type="text"
                                name="Valor"
                                defaultValue={newDataObject["Valor"]?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                mask="currency"

                            />
                        </Flex>)}


                    </Flex>
                </Flex>


                <Flex
                    mt="50px"
                    gap="17px"
                    flexDirection="column"
                    w="100%"
                >
                    <Text
                        mb="8px"
                    >
                        Detalhes
                    </Text>
                    <Flex
                        justifyContent="space-between"
                        w="100%"

                    >
                        <Text
                            fontSize="14px"
                            color="rgba(138, 148, 168, 1)"
                        >
                            Método
                        </Text>
                        <Text
                            w="363px"
                            color="black"
                            fontWeight="400"
                            fontSize="14px"

                        >
                            {newDataObject["Método"]}
                        </Text>
                    </Flex>
                    <Flex
                        justifyContent="space-between"
                        w="100%"

                    >
                        <Text
                            fontSize="14px"
                            color="rgba(138, 148, 168, 1)"
                        >
                            {/* Código de barras */}
                            {/* {newDataObject["Método"]} */}
                            {paymentMethodEdit(newDataObject)}
                        </Text>

                        {editBills === false ? (

                            <Text
                                w="363px"
                                color="black"
                                fontWeight="400"
                                fontSize="14px"

                            >
                                {paymentMethod(newDataObject)}
                            </Text>


                        ) : (
                            <Flex
                                w="363px"
                                h="32px"
                            >
                                <Input
                                    onChange={(e) => handleChange(paymentMethodEdit(newDataObject), e.target.value)}
                                    type="text"
                                    name="Boleto - Código"
                                    defaultValue={paymentMethod(newDataObject)}
                                />
                            </Flex>
                        )}
                    </Flex>
                    {/* {
                        newDataObject["Método"] === "Boleto" && (


                            <Flex
                                justifyContent="space-between"
                                w="100%"
                                className="input-file"
                            >
                                <Text
                                    fontSize="14px"
                                    color="rgba(138, 148, 168, 1)"
                                >
                                    Upload do boleto
                                </Text>
                                {editBills === false ? (

                                    <Text
                                        w="363px"
                                        color="black"
                                        fontWeight="400"
                                        fontSize="14px"

                                    >{arquivoSelecionado !== "" ? <Text color="rgba(22, 119, 255, 1)">
                                        {arquivoSelecionado}
                                    </Text> : " insira sua conta aqui"}

                                    </Text>


                                ) : (
                                    <Flex
                                        w="363px"
                                        h="32px"
                                        className="teste"
                                    >
                                        <ChakraProvider>
                                            <div>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: "none" }}
                                                    accept=".pdf"
                                                    onChange={handleFileChange}
                                                />
                                                <label htmlFor="fileInput">

                                                    <Flex w="350px" justifyContent="space-between">
                                                        <Flex >
                                                            <Img w="24px" h="24px"
                                                                src="/images/icons/PaperClipOutlined.svg"

                                                            />
                                                            <Text color="rgba(22, 119, 255, 1)">
                                                                {arquivoSelecionado}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                </label>
                                            </div>
                                        </ChakraProvider>
                                        {arquivoSelecionado !== "" &&
                                            (<Img w="22px" h="22px"
                                                zIndex={999}
                                                src="/images/icons/Trash.svg"
                                                onClick={() => setArquivoSelecionado("")}
                                            />)

                                        }


                                    </Flex>
                                )}

                            </Flex>
                        )
                    } */}
                    <Flex
                        justifyContent="space-between"
                        w="100%"

                    >
                        <Text
                            fontSize="14px"
                            color="rgba(138, 148, 168, 1)"
                        >
                            Tipo de conta
                        </Text>
                        <Text
                            w="363px"
                            color="black"
                            fontWeight="400"
                            fontSize="14px"

                        >
                            {newDataObject["Tipo de conta"]}
                        </Text>
                    </Flex>
                </Flex>
                <Divider borderColor="rgba(0, 0, 0, 0.06)" m="33px 0 20px 0" />
                {(billsData.Status !== "Paga" && billsData.Status !== "Cancelada") && (
                    <Flex
                        w="100%"
                        justifyContent="flex-start"

                    >
                        <Flex

                            alignContent="center"
                            alignItems="center"
                            gap="8px"
                            cursor="pointer"
                            onClick={() => updateCancelBills(billsData.id)}
                        >
                            <Img w="12px" h="12px"
                                zIndex={999}
                                src="/images/icons/Trash.svg"
                            />
                            <Text
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="22px"
                            >
                                Excluir conta
                            </Text>

                        </Flex>
                    </Flex>

                )}

                <Flex
                    w="100%"
                    h="100%"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    flexDirection="column"
                >
                    <Divider borderColor="rgba(0, 0, 0, 0.06)" m="33px 0 20px 0" />
                    {
                        billsData.Status === "Aprovar" && (

                            <Flex
                                gap="12px"
                            >
                                <Button
                                    disabled={editBills}
                                    backgroundColor="white"
                                    border="1px solid rgba(0, 0, 0, 0.15)"
                                    fontSize="14px"
                                    fontWeight="400"
                                    borderRadius="2px"
                                    lineHeight="22px"
                                    w="82px"
                                    height="32px"
                                    onClick={() => AproveOrReprove("Reprovado")}
                                >
                                    Reprovar
                                </Button>
                                <Button
                                    disabled={editBills}

                                    backgroundColor="rgba(24, 144, 255, 1)"
                                    color="rgba(255, 255, 255, 1)"
                                    w="71"
                                    h="32px"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    borderRadius="2px"
                                    height="32px"
                                    onClick={() => AproveOrReprove("Aprovado")}
                                >
                                    Aprovar para pagamento
                                </Button>
                            </Flex>
                        )

                    }
                    {
                        (billsData.Status === "Reprovado" ||
                            billsData.Status === "Atualização necessária" ||
                            billsData.Status === "Erro no pagamento" ||
                            billsData.Status === "Saldo insuficiente")
                        && (

                            <Flex
                                gap="12px"
                            >

                                <Button
                                    disabled={editBills}

                                    backgroundColor="rgba(24, 144, 255, 1)"
                                    color="rgba(255, 255, 255, 1)"
                                    w="71"
                                    h="32px"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    borderRadius="2px"
                                    height="32px"
                                    onClick={() => AproveOrReprove("Aprovado")}
                                >
                                    Enviar para aprovação
                                </Button>
                            </Flex>
                        )

                    }
                    {
                        (billsData.Status === "Aprovado" ||
                            billsData.Status === "Pagamento agendado")
                        && (

                            <Flex
                                gap="12px"
                            >

                                <Button
                                    disabled={editBills}

                                    backgroundColor="white"
                                    color="rgba(0, 0, 0, 0.85)"
                                    border="1px solid rgba(0, 0, 0, 0.15)"
                                    w="51"
                                    h="32px"
                                    p="0 15px"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    borderRadius="2px"
                                    height="32px"
                                    onClick={() => setConfirmUpdateBills(true)}
                                >
                                    OK
                                </Button>
                            </Flex>
                        )

                    }
                    {
                        (billsData.Status === "Paga" ||
                            billsData.Status === "Cancelada")
                        && (

                            <Flex
                                gap="12px"
                            >

                                <Button
                                    backgroundColor="white"
                                    color="rgba(0, 0, 0, 0.85)"
                                    border="1px solid rgba(0, 0, 0, 0.15)"
                                    w="51"
                                    h="32px"
                                    p="0 15px"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    borderRadius="2px"
                                    height="32px"
                                    onClick={() => setOpenEditeBills(false)}
                                >
                                    OK
                                </Button>
                            </Flex>
                        )

                    }

                </Flex>

                {
                    confirmUpdateBills && (

                        <Flex position="absolute" top="0" left="0" zIndex={999} h="100%" w="100%"
                            justifyContent="end"
                            alignContent="center"
                            alignItems="center"
                            bg="rgb(0,0,0, 0.62)"
                            p="91px"
                        >
                            <Flex
                                top="40%"
                                borderRadius="2px"
                                zIndex="999"
                                w="400px"
                                h="214px"
                                justifyContent="flex-start"
                                alignContent="flex-start"
                                alignItems="flex-start"
                                backgroundColor="white"
                                boxShadow={{ base: "0px 3px 6px -4px rgba(0, 0, 0, 0.12)", md: "0px 6px 16px 0px rgba(0, 0, 0, 0.08)" }}
                                p="32px 32px 24px"
                                gap="16px"
                            >

                                <Img w="21px" h="21px"
                                    zIndex={999}
                                    src="/images/icons/InfoCircleOutlined.svg"
                                // onClick={() => setArquivoSelecionado("")}
                                />
                                <Flex
                                    flexDirection="column"
                                    gap="8px"
                                    justifyContent="flex-start"
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                    h="100%"
                                >
                                    <Text
                                        fontWeight="600"
                                        fontSize="16px"
                                        lineHeight="25.41px"

                                    >
                                        Essa conta será paga apenas no próximo dia de pagamento.
                                    </Text>
                                    <Text
                                        fontWeight="400"
                                        fontSize="14px"
                                        lineHeight="22px"

                                    >
                                        Você está editando uma conta após o horário limite.
                                    </Text>

                                    <Flex
                                        h="100%"
                                        w="100%"
                                        justifyContent="flex-end"
                                        alignContent="flex-end"
                                        alignItems="flex-end"
                                    >
                                        <Button
                                            backgroundColor="rgba(24, 144, 255, 1)"
                                            color="rgba(255, 255, 255, 1)"
                                            w="49px"
                                            h="32px"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            borderRadius="2px"
                                            height="32px"
                                            onClick={() => editeAndSaveBills()}
                                        >
                                            OK
                                        </Button>

                                    </Flex>
                                </Flex>


                            </Flex>
                        </Flex>
                    )
                }

                {
                    confirmUpdateBillsAgain && (

                        <Flex position="absolute" top="0" left="0" zIndex={999} h="100%" w="100%"
                            justifyContent="end"
                            alignContent="center"
                            alignItems="center"
                            bg="rgb(0,0,0, 0.62)"
                            p="60px"
                        >
                            <Flex
                                top="40%"
                                borderRadius="2px"
                                zIndex="999"
                                w="461px"
                                h="214px"
                                justifyContent="flex-start"
                                alignContent="flex-start"
                                alignItems="flex-start"
                                backgroundColor="white"
                                boxShadow={{ base: "0px 3px 6px -4px rgba(0, 0, 0, 0.12)", md: "0px 6px 16px 0px rgba(0, 0, 0, 0.08)" }}
                                p="32px 32px 24px"
                                gap="16px"
                            >

                                <Img w="21px" h="21px"
                                    zIndex={999}
                                    src="/images/icons/InfoCircleOutlined.svg"
                                // onClick={() => setArquivoSelecionado("")}
                                />
                                <Flex
                                    flexDirection="column"
                                    gap="8px"
                                    justifyContent="flex-start"
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                    h="100%"
                                >
                                    <Text
                                        fontWeight="600"
                                        fontSize="16px"
                                        lineHeight="25.41px"

                                    >
                                        Tem certeza que deseja enviar conta para aprovação novamente?
                                    </Text>
                                    <Text
                                        fontWeight="400"
                                        fontSize="14px"
                                        lineHeight="22px"

                                    >
                                        Você está editando uma conta que já foi aprovada, será necessária aprovação novamente.
                                    </Text>

                                    <Flex
                                        h="100%"
                                        w="100%"
                                        justifyContent="flex-end"
                                        alignContent="flex-end"
                                        alignItems="flex-end"
                                        gap="8px"
                                    >
                                        <Button
                                            backgroundColor="white"
                                            color="rgba(0, 0, 0, 0.85)"
                                            border="1px solid rgba(0, 0, 0, 0.15)"
                                            h="32px"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            borderRadius="2px"
                                            height="32px"
                                            onClick={() => setConfirmUpdateBillsAgain(false)}
                                        >
                                            Rejeitar alterações
                                        </Button>
                                        <Button
                                            backgroundColor="rgba(24, 144, 255, 1)"
                                            color="rgba(255, 255, 255, 1)"
                                            h="32px"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            borderRadius="2px"
                                            height="32px"
                                            onClick={() => editeAndSendForAprove()}
                                        >
                                            Enviar para aprovação
                                        </Button>

                                    </Flex>
                                </Flex>


                            </Flex>
                        </Flex>
                    )
                }


            </Flex>

        </Flex >
    )
}