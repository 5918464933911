import { ChartMetrics } from "@/models";
import { LateTotalIcon } from "@/styles/icons/LateTotalIcon";
import { PaidTotalIcon } from "@/styles/icons/PaidTotalIcon";
import { ScheduledTotalIcon } from "@/styles/icons/ScheduledTotalIcon";
import { formatCurrency } from "@/utils";
import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

export function DashboardChartMetrics({paidAmount, lateAmount, scheduledAmount }: ChartMetrics) {
  return (
    <Flex
      w="100%"
      p="2rem 0 1rem 0"
      justify="space-around"
      color="black"
    >
      <Flex
        flexDir="column"
      >
        <Flex
          justify="space-around"
          align="center"
          gap="2"
        >
          <PaidTotalIcon/>
          <Text>Recebido</Text>
          <Tooltip
            maxW="240px"
            hasArrow
            placement="top"
            label="Receita de cobranças que foram compensadas no período. Esse valor pode ou não já ter sido repassado."
          >
            <span>
              <Icon
                marginLeft=".2rem"
                marginTop=".2rem"
                w=".9rem"
                color="gray.300"
                as={AiOutlineInfoCircle}
              />
            </span>
          </Tooltip>
        </Flex>
        <Flex
          align="flex-end"
        >
          <Text 
            color="gray.300" 
            fontSize="13px"
            marginBottom=".2rem"
            marginRight=".3rem"
          >
            R$
          </Text>
          <Text
            fontWeight="bold"
            fontSize="24px"
            display="inline-block"
          >
            {paidAmount > 0 ? formatCurrency({value: paidAmount}).split("R$") : "-"}
          </Text>
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
      >
        <Flex
          justify="space-between"
          align="center"
          gap="2"
        >
          <LateTotalIcon/>
          <Text>Em atraso</Text>
          <Tooltip
            maxW="240px"
            hasArrow
            placement="top"
            label="Total de receita das cobranças
            com atraso no pagamento"
          >
            <span>
              <Icon
                marginLeft=".2rem"
                marginTop=".2rem"
                w=".9rem"
                color="gray.300"
                as={AiOutlineInfoCircle}
              />
            </span>
          </Tooltip>
        </Flex>
        <Flex 
          align="flex-end"
        >
          <Text
            color="gray.300" 
            fontSize="13px"
            marginBottom=".2rem"
            marginRight=".3rem"
          >
            R$
          </Text>
          <Text
            fontWeight="bold"
            fontSize="24px"
            display="inline-block"
          >
            {lateAmount > 0 ? formatCurrency({value:lateAmount}).split("R$") : "-"}
          </Text>
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
      >
        <Flex
          justify="space-between"
          align="center"
          gap="2"
        >
          <ScheduledTotalIcon/>
          <Text>A receber</Text>
          <Tooltip
            maxW="240px"
            hasArrow
            placement="top"
            label="Total de receita das cobranças
            agendadas para o período"
          >
            <span>
              <Icon
                marginLeft=".2rem"
                marginTop=".2rem"
                w=".9rem"
                color="gray.300"
                as={AiOutlineInfoCircle}
              />
            </span>
          </Tooltip>
        </Flex>
        <Flex
          align="flex-end"
        >
          <Text
            color="gray.300" 
            fontSize="13px"
            marginBottom=".2rem"
            marginRight=".3rem"
          >
            R$
          </Text>
          <Text
            fontWeight="bold"
            fontSize="24px"
            display="inline-block"
          >
            {scheduledAmount > 0 ? formatCurrency({value: scheduledAmount}).split("R$") : "-"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}