import { useSettings } from "@/hooks";
import { showToast } from "@/utils";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PaymentMethodProps } from "@/models";
import { Method } from "../Method";
import React from "react";

export function PaymentMethod({
  onCreation,
  creditCardType,
  data,
  register,
  control,
  errors,
  split,
  initialData,
  setValue,
  watch,
  anchoringState,
  paymentLinkType,
  setCreditCardType,
  setAnchoringState,
  isPunctualCharge,
  dateSwitchState,
  setDateSwitchState,
  setIsOk,
  hasInstallmentMix
}: PaymentMethodProps) {
  const { getSellerCheckoutDefault } = useSettings();
  const [paymentAcepts, setPaymentAcepts] = useState([]);
  async function getPaymentMethodsDefault() {
    try {
      const { data } = await getSellerCheckoutDefault();
      setValue("enableBankSlip", data.defaultEnableBankSlip);
      setValue("enableCreditCard", data.defaultEnableCreditCard);
      setValue("enablePix", data.defaultEnablePixPayment);
      setValue("enableSplit", data.enableSplitOperation);

    } catch (error) {
      showToast({
        type: "error",
        message: "Falha ao carregar as informações dos métodos de pagamento.",
      });
    }
  }
  const [paymentsMethodsActive, setPaymentsMethodsActive] = useState(
    {
      enableCreditCard: false,
      enableBankSlip: false,
      enablePix: false
    }
  )

  useEffect(() => {
    if (onCreation) {
      getPaymentMethodsDefault();
    }
  }, []);



  // useEffect(() => {

  //   if (paymentsMethodsActive.enableBankSlip === true ||
  //     paymentsMethodsActive.enableCreditCard === true ||
  //     paymentsMethodsActive.enablePix === true) {
  //     setIsOk((prevState) => ({
  //       ...prevState,
  //       paymentMethods: true
  //     }))
  //   }else{
  //     setIsOk((prevState) => ({
  //       ...prevState,
  //       paymentMethods: false
  //     }))
  //   }
  // }, [paymentsMethodsActive])

  return (
    <>
      <Flex flexDir="column" w="100%">
        {data?.map((item, index) => (
          < React.Fragment key={index} >
            <Method
            hasInstallmentMix={hasInstallmentMix}
              setPaymentsMethodsActive={setPaymentsMethodsActive}
              initialData={initialData}
              split={split}
              setValue={setValue}
              creditCardType={creditCardType}
              setCreditCardType={setCreditCardType}
              register={register}
              control={control}
              reg={item.reg}
              key={item.tagName}
              id={item.tagName}
              title={item.name}
              watch={watch}
              anchoringState={anchoringState}
              paymentLinkType={paymentLinkType}
              setAnchoringState={setAnchoringState}
              isPunctualCharge={isPunctualCharge}
              dateSwitchState={dateSwitchState}
              setDateSwitchState={setDateSwitchState}
            />
            {data.length > index + 1 && <Divider borderColor="newGray.100" />}
          </ React.Fragment>
        ))}
        {errors && (
          <Text
            marginLeft="1.5rem"
            fontSize=".8rem"
            color="danger"
            mb="4"
            role="alert"
          >
            {errors.message}
          </Text>
        )}
      </Flex>
    </>
  );
}
