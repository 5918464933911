import {
    Button,
    Modal,
    ModalProps,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Flex,
  } from "@chakra-ui/react";
  
  interface FormModalProps extends ModalProps {
    isOpen: boolean;
    isSubmitting: boolean;
    onClose: () => void;
    handleConfirmModal: () => void;
    title: string;
    confirmationButtonText?: string;
    disableWhenIsFormEmpty?: boolean;
  }
  
  const FormModal: React.FC<FormModalProps> = ({
    isOpen,
    isSubmitting,
    onClose,
    handleConfirmModal,
    children,
    isCentered = true,
    title,
    confirmationButtonText = "Adicionar",
    disableWhenIsFormEmpty = false,
  }) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
        <ModalOverlay />
        <ModalContent  as="form" onSubmit={handleConfirmModal}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody 
            p="24px" 
            borderTop="1px solid" 
            borderBottom="1px solid" 
            borderColor="gray.50"
          >
            {children}
          </ModalBody>
          <ModalFooter>
            <Flex 
                w="100%"
                justifyContent="right"
                gap={4}
            >
                <Button 
                    variant="outline"
                    onClick={onClose}
                    borderRadius="2px"
                >
                  Cancelar
                </Button>
                <Button 
                    id={confirmationButtonText === "Adicionar" ? "recebimento_manual" : ""}
                    variant="primary"
                    type="submit" 
                    isDisabled={isSubmitting || disableWhenIsFormEmpty}
                    isLoading={isSubmitting}
                    _disabled={{
                      bgColor: "neutral.3",
                      border: "1px solid",
                      borderColor: "neutral.200",
                      cursor: "not-allowed",
                      color: "rgba(0, 0, 0, 0.25)",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleConfirmModal();
                    }}
                >
                { confirmationButtonText }
                </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  export { FormModal }